<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Search -->
        <!-- <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-daterange
                    v-model="range"
                    :presets="presets"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    display-format="dd/MM/yyyy"
                    @input="onDateRangeChange"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card> -->
        <!-- Receive list -->
        <material-card
          :title="$t('report.quantityVoucherSoldInAgent.list')"
          color="green"
          flat
          full-width
        >
          <!-- <v-btn
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon>mdi-file-export</v-icon>
            <span style="text-transform: none;">{{ $t('voucherReport.exportExcel') }}</span>
          </v-btn> -->
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherReportHeaders"
            :items="voucherReportList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherReportHeaders"
                :key="header.value + index"
              >
                <span>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    voucherReportHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherHistory.dateAction', value: 'dateAction' },
      { text: 'report.quantityVoucherSupplierToAgent.workpieceCode', value: 'workpieceCode' },
      { text: 'evoucher.issuance.workpiece', value: 'issuanceCode' },
      { text: 'report.releaseAgent', value: 'fromEntity' },
      { text: 'report.quantityVoucherSoldInAgent.numberReleased', value: 'quantity' },
      { text: 'roles.type.agent', value: 'toEntity' },
      { text: 'report.quantityVoucherSoldInAgent.voucherSold', value: 'voucherSold' },
      { text: 'report.quantityVoucherSoldInAgent.customerReceived', value: 'customerReceived' },
      { text: 'report.quantityVoucherSoldInAgent.customerHaveUsed', value: 'customerUsed' }
    ],
    voucherReportList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'QUANTITY_VOUCHER_SOLD_IN_AGENT_DATA'
    ])
  },
  watch: {
    QUANTITY_VOUCHER_SOLD_IN_AGENT_DATA () {
      let res = this.QUANTITY_VOUCHER_SOLD_IN_AGENT_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherReportList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          dateAction: this.formatDate(data[i].date_created),
          workpieceCode: data[i].workpiece ? data[i].workpiece.code : '',
          issuanceCode: functionUtils.concatSuffixPrefix(data[i].issuance),
          quantity: data[i].quantity,
          fromEntity: data[i].from_entity.name,
          voucherSold: data[i].vouchers_sold,
          customerReceived: data[i].vouchers_delivered,
          customerUsed: data[i].vouchers_used,
          toEntity: data[i].to_entity && data[i].to_entity.name
        }
        this.voucherReportList.push(obj)
      }
    }
  },
  created () {
    this.getQuantityVoucherSoldInAgent()
  },
  methods: {
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getQuantityVoucherSoldInAgent()
    },
    /**
     * Get report statistical quantity voucher supplier to agent
     */
    getQuantityVoucherSoldInAgent: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_SOLD_IN_AGENT(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_QUANTITY_VOUCHER_SOLD_IN_AGENT'
    ])
  }
}
</script>

<style>

</style>
